<template>
  <div class="g_page_box" style="background: #F7F7F7;">
    <!-- <ul class="tab">
      <li @click="tab_change(i)" v-for="i in tab_list" :key="i.type">
        {{i.name}}
        <div v-if="active === i.type" class="line"/>
      </li>
    </ul> -->
    <div class="g_main_content order" >
      <div class="no-data" v-if="list.length===0&&!loading">
        <img width="185" src="../../assets/img/order/no-date.png"/>
        暂无数据
      </div>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh" class="list" v-else>
        <van-list
          :offset="100"
          v-model="loading"
          :finished="finished"
          finished-text=""
          @load="onLoad"
        >
        <div v-for="(item,index) in list" :key="index" class="order-i">
          <div class="i-head">
            <div :class="['i-type',{'i-check': item.order_type === 1}]">{{item.order_type === 1 ? '检验' : '检查'}}</div>
            <span class="name">{{item.order_title&&item.order_title.join('、')}}</span>
            <span :class="['state',{'state-finish': item.lab_report_state === 1},{'state1': item.lab_report_state ===6 || item.lab_report_state ===5},{'state2': item.lab_report_state === 7},]">{{item.lab_report_state_label}}</span>
          </div>
          <div v-if="item.lab_report_state === 1" class="wait-order">
            <van-icon  name="warning" style="margin-right:5px;font-size: 18px;color: #FEEDED"/>
            请预约检查时间
          </div>
           <div v-else-if="item.lab_report_state ===7" class="wait-order">
            <van-icon  name="warning" style="margin-right:5px;font-size: 18px;color: #FEEDED"/>
            您的身体状况不适合做无痛检查请咨询问诊
          </div>
          <div v-else-if="+item.pay_state ===1&&item.lab_report_state ===6" class="wait-order wait-order-1">
            <van-icon name="warning" style="margin-right:5px;font-size: 18px;color:#0088FF;"/>
            请点击支付完成预约
          </div>
          <div v-else-if="item.lab_report_state ===5" class="wait-order wait-order-1">
            <van-icon name="warning" style="margin-right:5px;font-size: 18px;color:#0088FF;"/>
            等待医生进行麻醉评估
          </div>
          <div class="i-content">
            {{item.shop_title}}
            <div class="patient">
              <span class="patient-name" >{{item.patient_name}} <span v-if="item.patient_age">{{item.patient_age}}岁</span></span>
              <template v-if="item.lab_report_state === 1">
                待预约
              </template>
              <template v-else-if="+item.pay_state ===1&&item.lab_report_state ===6">
                等待支付
              </template>
              <template else>
                {{format_date(item.reservation_day,'yyyy年MM月dd日')}}
             </template>
            </div>
          </div>
          <div class='i-foot'>
            <div class="i-button" v-if="item.lab_report_state === 1" @click="to_order(item)">预约</div>
            <template v-else>
              <div  class="i-button" @click="look_detail(item)">{{+item.pay_state ===1&&item.lab_report_state ===6 ? '支付' : '查看'}}</div>
            </template>
          </div>
        </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
import {get_order_list} from './service'
import {format_date} from '../../utils/format'
export default {
  data () {
    return {
      tab_list:[{type: 7,name:'全部'},{type: 8,name:'检验'},{type: 9,name:'检查'},],
      active: 2,
      list: [],
      current_list: [],
      refreshing: false,
      finished:false,
      loading: false,
      timer: null,
      page_size: 5,
      page: 0,
      total: 0,
      error: false
    }
  },
  created() {
    this.search_order_list()
  },
  methods: {
    format_date,
    tab_change(i) {
      this.active = i.type
      this.current_list = []
      this.list = []
      this.page = 0
      this.total = 0
      this.search_order_list()
    },
    onLoad() {
      if(this.timer) clearTimeout(this.timer)
      if (this.refreshing) {
        this.loading = true
        this.list = []
        this.current_list = []
        this.page = -1
        this.refreshing = false
      }
      if(this.list.length>0&&this.list.length === this.total || this.error || this.total === 0) {
        this.finished = true
        this.error = false
        this.loading = false
        return
      }

      this.timer = setTimeout(()=>{
        this.page = this.page + 1
        this.search_order_list()
      },1000)
    },
    onRefresh() {
      this.finished = false
      this.loading = false
      this.onLoad()
    },
    look_detail (val) {
      if(+val.order_type === 6&&+val.lab_report_state === 2) {
        this.$router.push({
          path: '/my-colonoscopy-detail',
          query: {
            order_id: val.id,
          }
        })
      } else if(+val.order_type !== 6&&+val.lab_report_state === 2){
        this.$router.push({
          path: '/my-detail-process',
          query: {
            order_id: val.id
          }
        })
      } else {
        let params = val.lab_report_state===5 ? {} : {order_id: val.id}
        this.$router.push({
          path: '/anesthesia-result',
          query: {
            ...params
          }
        })
      }

    },
    to_order(val) {
      this.$router.push({
        path: '/my-check-detail',
        query: {
          order_id: val.group_id,
          item_type: 2
        }
      })
    },
    async search_order_list () {
      const {active} = this
      let {page,page_size} = this
      let params = {page,page_size,order_type:active}
      try {
        this.loading = true
        const {data} = await get_order_list(params)
        this.current_list = data.data
        this.total = data.total_elements || 0
        this.list = this.list.concat(data.data)
      }catch(e) {
        this.error = true
        console.log(e)
      } finally {
        this.loading =  false
      }

    }
  }
}
</script>
<style scoped>
.order {
  padding-top: 19px;

}
.list {
  padding:0 19px;
}
.tab {
  display: flex;
  height: 50px;
  background:#fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #565656;
  & >li {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    & .line {
      border: 2px solid #0088FF;
      margin-top: 4px;
      position: absolute;
      background: #0088FF;
      width: 40px;
      bottom: 9px;
    }
  }
}
.order-i {
  margin-bottom: 16px;
  background: #FFFFFF;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
  min-height: 178px;
  padding: 21px 16px 0;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  & .i-head {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    & .i-type {
      height: 21px;
      width: 42px;
      background: #00C43C;
      text-align: center;
      line-height: 21px;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      &.i-check {
        background: #0088FF;
      }
    }
    & .name{
      flex: 1;
      padding-left: 7px;
      font-size: 16px;
      font-weight: 500;
      line-height: 23px;
      color: #242938;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space:nowrap;
    }
    & .state {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color:#0088FF;
      padding-left: 12px;
      &.state-finish {
        color: #DF6363;
      }
      &.state1 {
        color:#00C43C;
      }
      &.state2 {
        color:#DF6363;
      }
    }
  }
  & .wait-order {
    height: 31px;
    background: #FEEDED;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #DF6363;
    display: flex;
    align-items: center;
    padding: 0 7px;
    margin-bottom: 8px;
    &.wait-order-1 {
      background: #f2f8fe;
      color: #0088FF;
    }
  }

  & .i-content {
    flex: 1;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #707070;
    padding-bottom: 18px;
    & .patient {
      display: flex;
      padding-top: 6px;
      & .patient-name {
        flex:1;
      }
    }

  }
  & .i-foot {
    border-top: 1px solid  rgba(112, 112, 112, 0.07);
    height: 63px;
    display: flex;
    justify-content: flex-end;
    padding-top: 13px;
    & .i-button {
      width: 66px;
      height: 28px;
      background: #0088FF;
      border-radius: 21px;
      display: flex;
      align-items: center;
      justify-content:center;
      font-size: 12px;
      font-weight: 400;
      line-height: 7px;
      color: #FFFFFF;
      margin-left: 9px;
      &.i-cancel {
        background: #FFFFFF;
        border: 1px solid #0088FF;
        color: #0088FF;
      }
    }
  }
}
.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 90px;
  & img {
    margin-bottom: 28px;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    color:rgba(112, 112, 112, 0.5);
  }
}

</style>
