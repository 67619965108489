import api from '../../utils/api'
// 登录
export async function get_order_list (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         // orders: {
  //         total_elements: 7,
  //         data: [
  //           {
  //             order_type: 1,
  //             order_title: '核磁共振共振',
  //             order_state: 1,
  //             order_state_label: '等待预约',
  //             location_label: '罗湖医院检验点',
  //             patient_name: '张三',
  //             patient_age: 34,
  //             reservation_day: new Date(),
  //             order_id: 1,
  //             medical_card_no: 123
  //           },
  //           {
  //             order_type: 1,
  //             order_title: '核磁共振共振',
  //             order_state: 2,
  //             order_state_label: '等待检查',
  //             location_label: '罗湖医院检验点',
  //             patient_name: '张三',
  //             patient_age: 34,
  //             reservation_day: new Date(),
  //             order_id: 2,
  //             medical_card_no: 123
  //           },
  //           {
  //             order_type: 1,
  //             order_title: '核磁共振共振',
  //             order_state: 3,
  //             order_state_label: '等待报告',
  //             location_label: '罗湖医院检验点',
  //             patient_name: '张三',
  //             patient_age: 34,
  //             reservation_day: new Date(),
  //             order_id: 3,
  //             medical_card_no: 123
  //           },
  //           {
  //             order_type: 2,
  //             order_title: '核磁共振共振',
  //             order_state: 4,
  //             order_state_label: '已出报告',
  //             location_label: '罗湖医院检验点',
  //             patient_name: '张三',
  //             patient_age: 34,
  //             reservation_day: new Date(),
  //             order_id: 4,
  //             medical_card_no: 123
  //           },
  //           {
  //             order_type: 2,
  //             order_title: '核磁共振共振',
  //             order_state: 1,
  //             order_state_label: '等待预约',
  //             location_label: '罗湖医院检验点',
  //             patient_name: '张三',
  //             patient_age: 34,
  //             reservation_day: new Date(),
  //             order_id: 5,
  //             medical_card_no: 123
  //           }
  //         ]
  //       }
  //       // }
  //     })
  //   }, 500)
  // })
  return api.get('/api/v1/mp/orders', {params:payload})
}

